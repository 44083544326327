<template>
  <v-card class="mx-auto" :disabled="tbl_ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_ldg || !tbl_data.length ? '' : ' (' + tbl_data.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.restitutions.create" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="success"
                :to="{
                  name: 'restitutions.create',
                }"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span v-text="'Crear'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            :label="'Inicial'"
            :model.sync="start_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            :label="'Final'"
            :model.sync="end_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Tipo"
            v-model="is_rha"
            :items="is_rha_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Estado"
            v-model="is_paid"
            :items="is_paid_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-autocomplete
            :disabled="tbl_data.length != 0"
            label="Contratante"
            v-model="contractor_id"
            :items="contractors"
            :loading="contractors_ldg"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col v-if="tbl_data.length == 0" cols="12">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTblData"
            :loading="tbl_ldg"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn block small color="info" @click.prevent="tbl_data = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
            :disabled="tbl_data.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="tbl_ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.paid_date="{ item }">
              {{ item.paid_date ? item.paid_date : "NO REG." }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left v-if="item.stamp_id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    small
                    dark
                    color="cyan darken-3"
                    @click.prevent="
                      downloadFile(item.stamp_id, 'pdf', item.is_rha)
                    "
                  >
                    <v-icon small> mdi-file-download </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Descargar PDF'" />
              </v-tooltip>
              <v-tooltip left v-if="item.stamp_id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    small
                    dark
                    color="teal darken-2"
                    @click.prevent="
                      downloadFile(item.stamp_id, 'xml', item.is_rha)
                    "
                  >
                    <v-icon small> mdi-xml </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Descargar XML'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    small
                    color="info"
                    :to="{
                      name: 'restitutions.show',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver detalle'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="bill_dlg" scrollable persistent max-width="1800">
      <v-card tile :disabled="bill_dlg_ldg" :loading="bill_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> FACTURA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="bill">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Folio'" />
                      <th v-text="'ID SM'" />
                      <th v-text="'Paciente'" />
                      <th v-text="'Oficina'" />
                      <th v-text="'Proveedor'" />
                      <th v-text="'Fecha envio'" />
                      <th v-text="'Total factura'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in bill.items" :key="i">
                      <td
                        class="text-caption font-weight-bold"
                        v-text="i + 1"
                      />
                      <td v-text="item.folio" />
                      <td v-text="item.enrollment" />
                      <td v-text="item.full_name" />
                      <td v-text="item.office_address" />
                      <td v-text="item.trade_name" />
                      <td v-text="item.insurance_reception_date" />
                      <td v-text="numberFormat(item.insurance_pay_amount)" />
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td
                        class="font-weight-bold"
                        v-text="numberFormat(bill.amount)"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12" class="txt-right">
              <v-btn color="success" @click.prevent="billSave">
                Generar Factura
                <v-icon right> mdi-file-upload </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgAlert,
  base64ToArrayBuffer,
  dateTimeToFile,
  dateTimeNow,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      tbl_ldg: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        {
          value: "index",
          text: "#",
          filterable: false,
          width: "60",
        },
        {
          value: "folio",
          text: "Restitución",
          filterable: false,
        },
        {
          value: "type",
          text: "Tipo",
          filterable: false,
        },
        {
          value: "created_at",
          text: "Creación",
          filterable: true,
        },
        {
          value: "created_by.email",
          text: "Creado por",
          filterable: true,
        },
        {
          value: "contractor.name",
          text: "Contratante",
          filterable: true,
        },
        {
          value: "stamp_folio",
          text: "Folio Fact.",
          filterable: true,
        },
        {
          value: "amount",
          text: "Monto",
          filterable: true,
        },
        {
          value: "restitution_items",
          text: "Registros",
          filterable: true,
        },
        {
          value: "paid_date",
          text: "F. pago",
          filterable: true,
        },
        {
          value: "action",
          text: "",
          sortable: false,
          filterable: false,
          width: "160",
        },
      ],
      bill: null,
      bill_dlg: false,
      bill_dlg_ldg: false,
      start_date: null,
      end_date: null,
      is_rha: 2,
      is_rha_opt: [
        {
          id: 1,
          name: "S. HOSPITALARIOS",
        },
        {
          id: 0,
          name: "S. MÉDICOS",
        },
        {
          id: 2,
          name: "TODOS",
        },
      ],
      is_paid: 2,
      is_paid_opt: [
        {
          id: 1,
          name: "PAGADOS",
        },
        {
          id: 0,
          name: "NO PAGADOS",
        },
        {
          id: 2,
          name: "TODOS",
        },
      ],
      contractor_id: null,
      contractors: [],
      contractors_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    getTblData() {
      this.tbl_ldg = true;

      Axios.get(
        URL_API +
          "/restitutions" +
          "?start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date +
          "&is_rha=" +
          this.is_rha +
          "&is_paid=" +
          this.is_paid +
          "&contractor_id=" +
          this.contractor_id,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.tbl_ldg = false;
      });
    },

    downloadFile(stamp_id, ext, is_rha) {
      this.loading = true;

      Axios.get(
        URL_API +
          "/bills/stamped/" +
          stamp_id +
          "/files/" +
          ext +
          "/" +
          (is_rha ? "1" : "2"),
        headersToken(this.login.token)
      ).then((response) => {
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          const blob = new Blob([base64ToArrayBuffer(response.data.data)], {
            type: "application/" + ext,
          });
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download =
            "SM_bill" + stamp_id + "_" + dateTimeToFile() + "." + ext;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(URL_API + "/contractors", headersToken(this.login.token)).then(
      (res) => {
        this.contractors = res.data.data;
        this.contractors.push({
          id: null,
          name: "TODOS",
        });
        this.contractors_ldg = false;
      }
    );

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>